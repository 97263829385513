<template>
  <div ref="widget">
    <a
      :href="post.url"
      class="p-2 flex gap-2"
      v-for="post in posts"
      :key="post.title"
    >
      <img
        class="rounded-[8px] w-[65px] h-[65px]"
        :src="post.avatar"
        :alt="post.title"
        width="50"
        height="50"
      />
      <div class="text-[0.9em]">
        <h3>
          <strong v-html="post.title"></strong>
        </h3>
        <a :href="post.category.url"
          ><span v-html="post.category.name"></span
        ></a>
      </div>
    </a>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "colunistas",
  data() {
    return {
      posts: [],
    };
  },
  computed: {
    host() {
      return process.env.NODE_ENV == 'production' ? '/wp-json' : "https://cm7brasil.com/wp-json";
    },
  },
  mounted() {
    this.observe(this.$refs.widget);
  },
  methods: {
    getPosts() {
      axios
        .get(`${this.host}/cm7/v1/colunistas?${new Date().getTime()}`)
        .then((res) => {
          this.posts = res.data;
        });
    },
    handleIntersect(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          observer.unobserve(entry.target);
          if (!this.posts.length) {
            this.getPosts();
          }
        }
      });
    },
    observe(targetElement) {
      const options = {
        root: null,
        rootMargin: "100px",
        threshold: 0,
      };
      const observer = new IntersectionObserver(this.handleIntersect, options);
      observer.observe(targetElement);
    },
  },
};
</script>
<style scoped>
@import url('@/assets/tailwind.css')
</style>