<template>
  <ul class="flex no-scrollbar gap-4 mb-2 px-2 max-w-[100vw] overflow-x-auto">
    <li class="px-2 py-1 rounded-md bg-gray-200 text-nowrap" v-for="item in menu" :key="item.url" v-html="item.title"></li>
  </ul>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      menu: [],
    };
  },
  computed: {
    host() {
      return process.env.NODE_ENV == "production"
        ? "/wp-json"
        : "https://cm7brasil.com/wp-json";
    },
  },
  mounted() {
    axios.get(`${this.host}/cm7/v1/menu`).then((res) => {
      this.menu = res.data;
    });
  },
};
</script>

<style scoped>
@import url("@/assets/tailwind.css");
</style>
