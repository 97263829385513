<template>
  <div ref="widget">
    <div
      v-if="posts.length && is_mobile"
      class="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-4 justify-center items-start p-2"
    >
      <div
        class="w-full shadow-md border bg-gray-100 relative rounded overflow-hidden drop-shadow-md"
        v-for="post in posts"
        :key="post.title.rendered"
      >
        <a :href="post.link" class="z-20 bg-white top-0 h-full flex flex-col">
          <span
            class="text-[0.8em] p-4 text-black"
            v-html="timeAgo(post.date)"
          ></span>
          <img
            class="mt-0 w-full"
            :src="image(post)"
            :alt="post.title.rendered"
          />
          <h3 class="font-bold p-4 text-[0.9em] text-black mt-auto">
            <a
              :href="post.link"
              v-html="post.title.rendered"
              class="line-clamp-3"
            ></a>
          </h3>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    image_size: { default: "cm7-card-small-col-3" },
    query: {},
  },
  data() {
    return {
      is_mobile: window.innerWidth < 800,
      posts: [],
      default_query: {
        per_page: 4,
        _embed: 1,
        exclude: "",
        _fields: [
          "id",
          "title",
          "slug",
          "date",
          "link",
          "_links.wp:term",
          "_embedded.wp:term",
          "_links.wp:featuredmedia",
          "_embedded.wp:featuredmedia",
        ],
        categories: [],
      },
    };
  },
  computed: {
    host() {
      return process.env.NODE_ENV == "production"
        ? "/wp-json"
        : "https://cm7brasil.com/wp-json";
    },
  },
  mounted() {
    this.observe(this.$refs.widget);
  },
  methods: {
    timeAgo(dateString) {
      const date = new Date(dateString);
      const seconds = Math.floor((new Date() - date) / 1000);

      let interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) + " anos atrás";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " meses atrás";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " dias atrás";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " horas atrás";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " minutos atrás";
      }
      return Math.floor(seconds) + " segundos atrás";
    },
    getPosts() {
      let params = Object.assign(this.default_query, this.$props.query);
      axios.get(`${this.host}/wp/v2/posts`, { params }).then((res) => {
        this.posts = res.data;
      });
    },
    handleIntersect(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          observer.unobserve(entry.target);
          if (!this.posts.length) {
            this.getPosts();
          }
        }
      });
    },
    observe(targetElement) {
      const options = {
        root: null,
        rootMargin: "100px",
        threshold: 0,
      };
      const observer = new IntersectionObserver(this.handleIntersect, options);
      observer.observe(targetElement);
    },

    image(post) {
      let image = post._embedded["wp:featuredmedia"][0].media_details;
      console.log(image);
      return image.sizes[this.image_size]?.source_url;
    },
    categories(post) {
      return post._embedded["wp:term"][0];
    },
    date(post) {
      const dataDoPost = post.date; // Esta é a string de data que você obteve
      const data = new Date(dataDoPost);
      const meses = [
        "janeiro",
        "fevereiro",
        "março",
        "abril",
        "maio",
        "junho",
        "julho",
        "agosto",
        "setembro",
        "outubro",
        "novembro",
        "dezembro",
      ];

      const dia = data.getDate();
      const mes = meses[data.getMonth()];
      const ano = data.getFullYear();
      const horas = data.getHours().toString().padStart(2, "0");
      const minutos = data.getMinutes().toString().padStart(2, "0");

      return `${dia} de ${mes} de ${ano} às ${horas}:${minutos}`;
    },
  },
};
</script>

<style scoped>
@import url("@/assets/tailwind.css");
</style>
